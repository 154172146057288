import request from "@/utils/request";

// user login
export function login(data) {
  return request({
    url: "/login",
    method: "post",
    data: data,
  });
}

// user register
export function register(data) {
  return request({
    url: "/register",
    method: "post",
    data: data,
  });
}

// forget password request mail
export function forgetPassword(data) {
    return request({
      url: "/forgot-password",
      method: "post",
      data: data,
    });
}

// reset password
export function changePassword(data) {
  return request({
    url: "/update-password",
    method: "post",
    data: data,
  });
}

// reset password otp verify
export function verifyOTP(data) {
  return request({
    url: "/verify-otp",
    method: "post",
    data: data,
  });
}

// get system config
export function getSystemConfig() {
  return request({
    url: "/system-info",
    method: "post",
  });
}
