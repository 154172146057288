import request from "@/utils/request";
// get all users
export function getUsers(query) {
  return request({
    url: "/user/list",
    method: "post",
    params: query,
  });
}
export function getUser(id) {
  return request({
    url: "/user/details",
    method: "post",
    params: { id },
  });
}
export function suspendUser(id) {
  return request({
    url: "/user/suspend",
    method: "post",
    params: { id },
  });
}
// user/status-change
export function statusChange(data) {
  return request({
    url: "/user/status-change",
    method: "post",
    data:data
  });
}
export function activeUser(id) {
  return request({
    url: "/user/suspend",
    method: "post",
    params: { recover: "recover", id: id },
  });
}
export function createUser(data) {
  return request({
    url: "/user/create",
    method: "post",
    data: data,
  });
}
export function updateUser(data) {
  return request({
    url: "/user/update",
    method: "post",
    data: data,
  });
}
//api call to update password by id
export function updateUserPassword(data) {
  return request({
    url: "/password-update",
    method: "post",
    data,
  });
}
// notification api section
export function notification(query) {
  return request({
    url: "/notification-list",
    method: "post",
    params: { query },
  });
}

export function readNotification({ id: id, is_read: is_read }) {
  return request({
    url: "/notification-read",
    method: "post",
    data: { id: id, is_read: is_read },
  });
}
