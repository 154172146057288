import {
  getXeroServiceStatus,
  updateXeroEnv,
  getXeroEnv,
  updateWeekendDays,
  getWeekendDays,
} from "@/api/global-config";
export default {
  namespaced: true,
  state: {
    mapConfig: {},
  },
  getters: {},
  mutations: {},
  actions: {
    // update mapConfig
    getXeroServiceStatus({ commit }) {
      return new Promise((resolve, reject) => {
        getXeroServiceStatus()
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get xero env list
    getXeroEnv({ commit }) {
      return new Promise((resolve, reject) => {
        getXeroEnv()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update xero env
    updateXeroEnv({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateXeroEnv(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get weekend days
    getWeekendDays({ commit }, data) {
      return new Promise((resolve, reject) => {
        getWeekendDays(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // update weekend days
    updateWeekendDays({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateWeekendDays(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
};
