import request from "@/utils/request";

// get all duties
export function getDuties(query) {
  return request({
    url: "/duty/list",
    method: "post",
    params: query,
  });
}

// get duty by id
export function getDuty(id) {
  return request({
    url: "/duty/info",
    method: "post",
    params: { id },
  });
}

// create duty
export function createDuty(duty) {
  return request({
    url: "/duty/store",
    method: "post",
    data: duty,
  });
}

// update duty
export function updateDuty(duty) {
  return request({
    url: "/duty/update",
    method: "post",
    data: duty,
  });
}
