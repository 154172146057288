// for development environment
// export const VUE_APP_BASE_URL="https://grypas.inflack.xyz/grypas-api/api/v1"
// export const VUE_IMG_BASE_URL = "https://grypas.inflack.xyz/grypas-api/";
// export const VUE_APP_BASE_URL="https://grypas.inflack.xyz/grypas-api/api/v1"
// export const VUE_IMG_BASE_URL = "https://grypas.inflack.xyz/grypas-api/";

// for production environment shiftplanner
// export const VUE_APP_BASE_URL="https://api-webapp.shiftplanner.com.au/api/v1"
// export const VUE_IMG_BASE_URL = "https://api-webapp.shiftplanner.com.au/";

// for production environment
// export const VUE_APP_BASE_URL = "https://grypas-au.inflack.xyz/grypas-au-api/api/v1";
// export const VUE_IMG_BASE_URL = "https://grypas-au.inflack.xyz/grypas-au-api/";

// for demo environment
export const VUE_APP_BASE_URL = "https://hrm.inflack.xyz/demo-api/api/v1";
export const VUE_IMG_BASE_URL = "https://hrm.inflack.xyz/demo-api/";
// export const VUE_APP_BASE_URL = "https://hrm.inflack.xyz/demo-api/api/v1";
// export const VUE_IMG_BASE_URL = "https://hrm.inflack.xyz/demo-api/";
