import {
  approveDocument,
  createEmployee,
  createEmployeeSkillQuestion,
  deleteDay,
  getDay,
  getEmployee,
  getEmployees,
  getQuestionList,
  getSkillTestResult,
  getSkills,
  getVisa,
  getVisaSubClass,
  saveSkillTest,
  updateBankInfo,
  updateCertificationInfo,
  updateDrivingLicenseInfo,
  updateEmployeeBasicInformation,
  updateKeenInfo,
  updatePassportInfo,
  updatePassword,
  updateVisaInfo,
  uploadPhoto,
  deleteEmployeeDocument,
  getEmployeeAssignedToAssignedByList,
  updateTaxInfo,
  getEmployeePaySlip,
} from "@/api/employee";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all employees
    getEmployees({ commit }, query) {
      return new Promise((resolve, reject) => {
        getEmployees(query)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get employee by id
    getEmployee({ commit }, id) {
      return new Promise((resolve, reject) => {
        getEmployee(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get skill
    getSkills({ commit }) {
      return new Promise((resolve, reject) => {
        getSkills()
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //get question list
    getQuestionList({ commit }, id) {
      return new Promise((resolve, reject) => {
        getQuestionList(id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create employee skill question
    createEmployee({ commit }, data) {
      //console.log(commit);
      return new Promise((resolve, reject) => {
        createEmployee(data)
          .then((response) => {
            console.log("check form store", response);
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // create employee skill question
    createEmployeeSkillQuestion({ commit }, data) {
      //console.log(commit);
      return new Promise((resolve, reject) => {
        createEmployeeSkillQuestion(data)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // update all employee information from here

    updateEmployeeBasicInformation({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateEmployeeBasicInformation(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateKeenInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateKeenInfo(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateBankInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateBankInfo(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updatePassportInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        updatePassportInfo(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateVisaInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateVisaInfo(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // employee tax information update store
    updateTaxInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateTaxInfo(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get visa
    getVisa({ commit }) {
      return new Promise((resolve, reject) => {
        getVisa()
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get visa sub class
    getVisaSubClass({ commit }, visa_id) {
      return new Promise((resolve, reject) => {
        getVisaSubClass(visa_id)
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateDrivingLicenseInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateDrivingLicenseInfo(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateCertificationInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateCertificationInfo(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updatePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        updatePassword(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // employee uploadPhoto
    uploadPhoto({ commit }, data) {
      return new Promise((resolve, reject) => {
        uploadPhoto(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // save skill test
    saveSkillTest({ commit }, data) {
      return new Promise((resolve, reject) => {
        saveSkillTest(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get skill test result
    getSkillTestResult({ commit }, employee_id) {
      return new Promise((resolve, reject) => {
        getSkillTestResult(employee_id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // approve doucment
    approveDocument({ commit }, data) {
      return new Promise((resolve, reject) => {
        approveDocument(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get day
    getDay({ commit }) {
      return new Promise((resolve, reject) => {
        getDay()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // delete day
    deleteDay({ commit }, query) {
      return new Promise((resolve, reject) => {
        deleteDay(query)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // delete employee document
    deleteEmployeeDocument({ commit }, query) {
      return new Promise((resolve, reject) => {
        deleteEmployeeDocument(query)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // get employee assigned to assigned by list
    getEmployeeAssignedToAssignedByList({ commit }, employee_id) {
      return new Promise((resolve, reject) => {
        getEmployeeAssignedToAssignedByList(employee_id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // get employee pay slip
    getEmployeePaySlip({ commit }, query) {
      return new Promise((resolve, reject) => {
        getEmployeePaySlip(query)
          .then(({ data }) => {
            let newData = data;
            resolve(newData);
          })
          .catch((error) => {
            reject(error);
          });
      })
    }
  },
};
