export default [
  {
    path: "/time-sheets",
    name: "time-sheets",
    component: () => import("@/views/time-sheet/index.vue"),
    meta: {
      resource: "Timesheet",
      action: "show menu",
    },
  },
  {
    path: "/time-sheets/create",
    name: "create-timesheet",
    component: () => import("@/views/time-sheet/create.vue"),
    meta: {
      resource: "Timesheet",
      action: "create",
    },
  },
  {
    path: "/time-sheets/edit/:id",
    name: "update-timesheet",
    component: () => import("@/views/time-sheet/edit.vue"),
    meta: {
      resource: "Timesheet",
      action: "update",
    },
  },
  {
    path: "/exceptions",
    name: "exceptions",
    component: () => import("@/views/exceptions/index.vue"),
    meta: {
      resource: "Timesheet",
      action: "show menu",
    },
  },
  {
    path: "/exception-create",
    name: "exception-create",
    component: () => import("@/views/exceptions/create.vue"),
    meta: {
      resource: "Timesheet",
      action: "show menu",
    },
  },
];
