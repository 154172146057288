import request from "@/utils/request";
// update mapConfig
export function getXeroServiceStatus() {
  return request({
    url: "/xero-service",
    method: "post",
  });
}

// get xero env list
export function getXeroEnv() {
  return request({
    url: "/system/env-list",
    method: "post",
  });
}

// update xero env
export function updateXeroEnv(keys) {
  return request({
    url: "/system/env-update",
    method: "post",
    data: { keys: keys },
  });
}

// get weekend days
export function getWeekendDays(data) {
  return request({
    url: "/system/get-weekends-days",
    method: "post",
    params: data,
  });
}

// update weekend days
export function updateWeekendDays(data) {
  return request({
    url: "/system/week-days-configure",
    method: "post",
    data: data,
  });
}
