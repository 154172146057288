import request from "@/utils/request";

// get all employees
export function getEmployees(query) {
  return request({
    url: "/employee/list",
    method: "post",
    params: query,
  });
}

// get employee by id
export function getEmployee(id) {
  return request({
    url: "/employee/info",
    method: "post",
    params: { id },
  });
}

// get skill list
export function getSkills() {
  return request({
    url: "/employee/skill-list",
    method: "post",
  });
}

// get skill list
export function getQuestionList(id) {
  return request({
    url: "/employee/question-list",
    method: "post",
    params: { id },
  });
}

// get all employees
export function createEmployee(data) {
  return request({
    url: "/employee/store",
    method: "post",
    data,
  });
}

// get all employees
export function createEmployeeSkillQuestion(data) {
  return request({
    url: "/employee/question-create",
    method: "post",
    data,
  });
}

// update all employee information

//api call to update Employee Basic information by id
export function updateEmployeeBasicInformation(data) {
  return request({
    url: "employee/update-basic-info",
    method: "post",
    data,
  });
}
//api call to update Employee Keen by id
export function updateKeenInfo(data) {
  return request({
    url: "employee/update-keen-info",
    method: "post",
    data,
  });
}

//api call to update Employee Keen by id
export function updateBankInfo(data) {
  return request({
    url: "employee/update-bank-info",
    method: "post",
    data,
  });
}

//api call to update Employee passport by id
export function updatePassportInfo(data) {
  return request({
    url: "employee/update-passport-info",
    method: "post",
    data,
  });
}
//api call to update Employee visa by id
export function updateVisaInfo(data) {
  return request({
    url: "employee/visa-store-update",
    method: "post",
    data,
  });
}
// employee tax information update api
export function updateTaxInfo(data) {
  return request({
    url: "employee/update-tax",
    method: "post",
    data,
  });
}
// get visa list
export function getVisa() {
  return request({
    url: "/employee/visa-list",
    method: "post",
  });
}
// get visa sub class
export function getVisaSubClass(visa_id) {
  return request({
    url: "/employee/visa-sub-class",
    method: "post",
    params: { visa_id },
  });
}

//api call to update Employee driving licence by id
export function updateDrivingLicenseInfo(data) {
  return request({
    url: "employee/update-driving-license-info",
    method: "post",
    data,
  });
}

//api call to update Employee certificate by id
export function updateCertificationInfo(data) {
  return request({
    url: "employee/update-certification-info",
    method: "post",
    data,
  });
}

//api call to update password by id
export function updatePassword(data) {
  return request({
    url: "password-update",
    method: "post",
    data,
  });
}
// employee/document-approve
export function approveDocument(data) {
  return request({
    url: "employee/document-approve",
    method: "post",
    data: data,
  });
}
// employee update photo
export function uploadPhoto(data) {
  return request({
    url: "/avatar-upload",
    method: "post",
    data,
  });
}

// save skill test
export function saveSkillTest(data) {
  return request({
    url: "/employee/skill-test-exam",
    method: "post",
    data,
  });
}

// get skill test result
export function getSkillTestResult(employee_id) {
  return request({
    url: "/employee/employee-marks",
    method: "post",
    params: { employee_id },
  });
}

// get day
export function getDay() {
  return request({
    url: "/employee/get-days",
    method: "post",
  });
}

// delete day
export function deleteDay(query) {
  return request({
    url: "/employee/available-time-delete",
    method: "post",
    params: query,
  });
}

// delete employee document
export function deleteEmployeeDocument(query) {
  return request({
    url: "/employee/remove-document",
    method: "post",
    params: query,
  });
}

// get employee assigned to assigned by list
export function getEmployeeAssignedToAssignedByList(employee_id) {
  return request({
    url: "/employee/customer-company-assign-list",
    method: "post",
    params: { user_id: employee_id },
  });
}

// get employee pay slip
export function getEmployeePaySlip(query) {
  return request({
    url: "/employee/payslip",
    method: "post",
    params: query,
  });
}
