export default [
  // *===============================================---*
  // *--------- Employee ---- ---------------------------------------*
  // *===============================================---*

  {
    path: "/employees",
    name: "employees",
    meta: {
      resource: "Employee",
      action: "show menu",
    },
    component: () => import("@/views/employee/index.vue"),
  },
  {
    path: "/employee-create",
    name: "employee-create",
    meta: {
      resource: "Employee",
      action: "create",
      navActiveLink: 'employees',
    },
    component: () => import("@/views/employee/create.vue"),
  },
  {
    path: "/employee-import",
    name: "employee-import",
    meta: {
      resource: "Employee",
      action: "create",
      navActiveLink: 'employees',
    },
    component: () => import("@/views/employee/import.vue"),
  },
  {
    path: "/employee-preview/:id",
    name: "employee-preview",
    meta: {
      resource: "Employee",
      action: "view",
      navActiveLink: 'employees',
    },
    component: () => import("@/views/employee/show.vue"),
  },
  {
    path: "/employee-profile-update/:id",
    name: "employee-profile-update",
    meta: {
      resource: "Employee",
      action: "update",
      navActiveLink: 'employees',
    },
    component: () => import("@/views/employee/profile.vue"),
  },
  {
    path: "/create-skill-question",
    name: "create-skill-question",
    meta: {
      resource: "Employee",
      action: "update",
      navActiveLink: 'employees',
    },
    component: () =>
      import("@/views/employee/components/create-skill-question.vue"),
  },
];
